.background-guide {
    background: #F2F2F2;
    flex-direction: column;
    padding: 30px 0px;
}

header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yolo-logo-blue {
    width: 150px;
}

h1 {
    font-size: 60px;
    font-family: 'AmsiPro Bold', sans-serif;
    margin: 0px 10px;
}

section {
    width: 65%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

article {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 1px;
}

.dynamic-third,
.static-third {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 1px;
}

.image {
    width: 80%;
    transition: width 300ms ease;
}

.image:hover {
    width: 90%;
}

.image-mobile {
    display: none;
}

.first-text,
.second-text,
.third-text,
.fourth-text {
    font-family: 'AmsiPro', sans-serif;
    font-size: 18px;
    text-align: center;
    margin-right: 20px;
}

@media (max-width: 600px) {

    .background-guide {
        padding: 0px 0px 40px 0px;
    }

    .yolo-logo-blue {
        width: 70px;
    }

    h1 {
        font-size: 25px;
    }

    header {
        margin-top: 100px;
    }

    section {
        flex-direction: column;
        height: 100vh;
    }

    article {
        width: 100%;
        height: 25%;
        flex-direction: row;
    }

    .dynamic-third {
        display: none;
    }

    .static-third {
        height: 100%;
        width: 100%;
        display: flex;
    }

    .image-mobile {
        display: block;
        width: 100%;
    }

    .image {
        display: none;
    }

    .first-text,
    .second-text,
    .third-text,
    .fourth-text {
        font-size: 15px;
        margin-right: 0px;
        margin: 5px;
    }
}

@media (min-width: 600px) and (max-width: 800px) {

    h1 {
        font-size: 40px;
        margin: 20px 10px;
    }

    .yolo-logo-blue {
        width: 100px;
    }

    .first-text,
    .second-text,
    .third-text,
    .fourth-text {
        font-size: 15px;
        margin-right: 10px;
    }

    .dynamic-third,
    .static-third {
        height: 120px;
    }
}

@media (min-width: 801px) and (max-width: 1024px) {
    
    h1 {
        font-size: 40px;
    }

    .yolo-logo-blue {
        width: 80px;
    }

    .first-text,
    .second-text,
    .third-text,
    .fourth-text {
        font-size: 15px;
        margin-right: 10px;
    }

    .dynamic-third,
    .static-third {
        height: 120px;
    }
}
