.background-about-us {
    background: #F2F2F2;
    flex-direction: column;
}

.title-about-us {
    font-family: 'AmsiPro Bold', sans-serif;
    color: #222536;
    text-align: center;
    margin-top: 0px;
}

.yolo-green-about-us {
    margin-left: 20px;
}

.container-about-us {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.rectangle-about-us {
    background-image: linear-gradient(0deg, transparent 0%, #FFFFFF 700%);
    border-radius: 15px;
    padding: 20px;
    width: 300px;
    text-align: center;
    color: #222536;
    font-family: 'AmsiPro', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0B65FF;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 25px;
}

.icon-about-us {
    margin-bottom: 15px;
}

.subtitle-about-us {
    font-family: 'AmsiPro Bold', sans-serif;
    color: #222536;
}

.rectangle-about-us p {
    margin: 0;
    font-size: 16px;
    font-family: 'AmsiPro', sans-serif;
    color: #222536;
    line-height: 20px;
    margin-top: 5px;
}

@media (min-width: 1101px) {

    .container-about-us {
        width: 784px;
    }

    .title-about-us {
        font-size: 40px;
    }

    .subtitle-about-us {
        font-size: 22px;
    }

}

@media (min-width: 600px) and (max-width: 1100) {
    .container-about-us {
        width: 80vw;
    }

    .title-about-us {
        font-size: 40px;
    }

    .subtitle-about-us {
        font-size: 22px;
    }

}

@media (max-width: 600px) {
    .container-about-us {
        width: 80vw;
    }

    .icon-about-us {
        width: 50px;
    }

    .title-about-us {
        font-size: 24px;
    }

    .subtitle-about-us {
        font-size: 18px;
    }

}