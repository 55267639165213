.background-add {
    background: #0B65FF;
    flex-direction: column;
}

.title-add {
    font-family: 'AmsiPro Bold', sans-serif;
    color: white;
    text-align: center;
    margin-top: 5vh;
}

.container-functioning-add {
    width: 100%;
    height: 100%;
    margin-bottom: 5vh;
}

.number-box-add {
    width: 100%;
    height: 100%;
}

.text-box-add {
    width: 100%;
    flex-direction: column;
}

.number-functioning {
    color: #0BFF93;
    font-size: 64px;
    font-family: 'AmsiPro Ultra', sans-serif;

}

.text-functioning {
    color: white;
    font-family: 'AmsiPro', sans-serif;
}

@media (min-width: 600px) {
    .text-functioning {
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 10px;
    }

    .title-add {
        font-size: 40px;
    }

    .text-functioning {
        font-size: 22px;
    }

    .container-functioning-add {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5vh;
        margin-left: 20vw;
    }

    .number-box-add {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
        margin-right: 100px;
    }

    .text-box-add {
        height: 100%;
    }

    .text-box-add {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

}

@media (max-width: 600px) {

    .title-add {
        font-size: 24px;
    }

    .text-functioning {
        font-size: 16px;
    }

    .container-functioning-add {
        flex-direction: column;
    }

    .text-box-add {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

}