.footer-container {
  background: #0B65FF;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-text {
  display: flex;
  flex-direction: column;
  font-family: 'AmsiPro Bold', sans-serif;
  font-size: 14px;
}

.footer-icons {
  display: flex;
  gap: 10px;
  margin-right: 12vw;
}

.footer-ig-green,
.footer-tw-green,
.footer-ln-green,
.footer-wpp-green,
.footer-fb-green {
  transition: transform 0.3s ease;
}

.footer-ig-green:hover,
.footer-tw-green:hover,
.footer-ln-green:hover,
.footer-wpp-green:hover,
.footer-fb-green:hover {
  transform: scale(1.1);
}