@media (max-width: 600px) {
    .title-data {
        font-size: 24px;
        margin-top: 20px;
    }

    .container-data {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .rectangle-data {
        width: 100%;
        margin: 10px 0;
        padding: 10px;
    }

    .subtitle-data {
        font-size: 18px;
    }

    .rectangle-data p {
        font-size: 14px;
        line-height: 18px;
    }
}

@media (min-width: 600px) {
    .title-data {
        font-size: 40px;
    }

    .rectangle-data {
        padding: 40px;
    }

    .subtitle-data {
        font-size: 22px;
    }

    .container-data {
        width: 900px;
    }
}

.background-data {
    background: #222635;
    flex-direction: column;
}

.title-data {
    font-family: 'AmsiPro Bold', sans-serif;
    color: white;
    text-align: center;
    margin-top: 0px;
}

.yolo-green-data {
    margin-left: 20px;
}

.container-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.rectangle-data {
    background-image: linear-gradient(0deg, transparent 0%, #FFFFFF 700%);
    border-radius: 15px;
    width: 300px;
    text-align: center;
    color: white;
    font-family: 'AmsiPro', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0BFF93;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
}

.icon-data {
    margin-bottom: 15px;
}

.subtitle-data {
    font-family: 'AmsiPro Bold', sans-serif;
    color: white;
}

.rectangle-data p {
    margin: 0;
    font-size: 16px;
    font-family: 'AmsiPro', sans-serif;
    color: white;
    line-height: 20px;
    margin-top: 20px;
}