@media (max-width: 600px) {
  .title-faq {
    font-size: 30px;
  }

  .faq-container{
    margin-top: 50px;
  }
}

@media (min-width: 600px) {
  .title-faq {
    font-size: 64px;
  }

  .faq-container{
    margin-top: 50px;
  }
}

.faq-background {
  background-color: #1a1b23;
  color: #fff;
  flex-direction: column;
  height: auto; 
}

.title-faq { 
  margin-top: 50px;
  text-align: center;
  color: white;
  font-family: 'AmsiPro Bold', sans-serif;
}

.faq-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: auto;
  margin-bottom: 10vh;
}

.faq-item {
  margin-bottom: 4vh;
  background-image: linear-gradient(0deg, transparent 0%, #FFFFFF 700%);
  border-radius: 15px;
  padding: 20px;
  width: 60vw;
  text-align: center;
  color: white;
  font-family: 'AmsiPro', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #0BFF93;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  float: left;
  width: 100%;
  font-size: 40px;
  font-family: 'AmsiPro Bold', sans-serif;
}

.faq-question span {
  font-size: 18px;
}

.faq-icon {
  transition: all 0.3s ease;
  float: right;
}

.faq-icon img {
  float: right;
}

.faq-icon.rotate {
  transform: rotate(180deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  padding: 0 10px;
  text-align: left;
  width: 100%;
}

.faq-answer.show {
  height: auto;
  max-height: 220px;
  padding: 10px 10px;
}