.background-contact {
    background: #FFFFFF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-direction: column;
}

h2 {
    font-family: 'AmsiPro Bold', sans-serif;
    color: #222635;
    text-align: center;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-row input {
    padding: 20px;
    padding-right: 10px;
    border: 1px solid #F2F2F2;
    background: #F2F2F2;
    border-radius: 8px;
    color: #222635;
    margin-left: 50px;
}

::placeholder {
    color: #222635;
    font-family: 'AmsiPro', sans-serif;
}

input {
    color: #222635;
    font-family: 'AmsiPro', sans-serif;
    margin-bottom: 10px;
}

button {
    display: block;
    padding: 10px;
    background: #0B65FF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'AmsiPro Bold', sans-serif;
    text-align: center;
    justify-content: center;
    float: right;
}

button:hover {
    background-color: #0056b3;
}

input[type="text"] {
    width: 100%;
}

@media (min-width: 600px) and (max-width: 1100px) {
    input {
        font-size: 22px;
    }

    h2 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    ::placeholder {
        font-size: 22px;
    }

    button {
        width: 150px;
        font-size: 32px;
    }

    input[type="text"] {
        width: 300px;
    }

    input[type="email"] {
        width: 300px;
    }

    input[type="tel"] {
        width: 300px;
    }
}

@media (min-width: 1101px) {
    input {
        font-size: 22px;
    }

    h2 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    ::placeholder {
        font-size: 22px;
    }

    button {
        width: 150px;
        font-size: 32px;
    }

    input[type="text"] {
        width: 500px;
    }

    input[type="email"] {
        width: 500px;
    }

    input[type="tel"] {
        width: 500px;
    }
}

@media (max-width: 600px) {
    .form-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
    }

    h2 {
        font-size: 24px;
    }

    input {
        font-size: 16px;
    }

    .form-row input {
        width: 100px;
        margin-left: 50px;
        margin-bottom: 25px;
        margin-right: 50px;
    }

    button {
        font-size: 16px;
        float: none;
        margin: 0 auto;
    }

    ::placeholder {
        font-size: 16px;
    }

    input[type="text"] {
        width: 325px;
        box-sizing: border-box;
        padding: 10px;
    }

    input[type="email"] {
        width: 325px;
        box-sizing: border-box;
        padding: 10px;
    }

    input[type="tel"] {
        width: 325px;
        box-sizing: border-box;
        padding: 10px;
    }
}