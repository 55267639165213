.navbar {
    height: 80px;
}

.yolo-icon {
    height: 45px;
    width: 125px;
}

.desktop-menu {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 50px;
    margin-right: 40px;

}

.selected-nav-route,
.nav-route {
    color: white;
    font-size: 16px;
    text-decoration: none;
    font-family: 'AmsiPro', sans-serif;
    padding: 10px 20px;
    transition: transform 0.4s ease;
}

.selected-nav-route {
    color: #0bff93;
    border-radius: 5px;
    font-weight: 600;
}

.nav-route:hover {
    transform: scale(1.1);
}

.social-media {
    display: none;
}

.menu-icon,
.text-access {
    display: none;
}

.yolo-icon {
    margin-left: 150px;
}

.user-icon {
    margin-left: 25px;
    margin-right: 150px;
}

@media (max-width: 600px) {

    .modal:focus {
        outline: none;
        border: none;
    }

    .desktop-menu {
        display: none;
    }

    .mobile-menu {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: end;
        background-color: #222536;
        border-top-left-radius: 25px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        margin: 0px;
        padding: 10px;
    }

    ul .nav-route {
        text-align: left;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .nav-route {
        color: white;
        margin: 5px 0px;
    }

    .access {
        display: flex;
        align-items: center;
    }


    .text-access {
        display: block;
        color: white;
        font-family: 'AmsiPro', sans-serif;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 20px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
        margin-right: 10vw;
        margin-left: 2vw;
        filter: brightness(0) saturate(100%) invert(91%) sepia(33%) saturate(3187%) hue-rotate(78deg) brightness(99%) contrast(100%);
        z-index: 5;
    }

    .yolo-icon {
        margin-left: 2vw;
    }

    .social-media {
        margin-top: 20px;
        width: 150px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .social-media img {
        width: 35px;
    }

    .user-icon {
        margin-left: 0px;
        margin-right: 0px;
    }

}

@media (min-width: 600px) and (max-width: 800px) {

    .menu-icon,
    .text-access {
        display: none;
    }

    .yolo-icon {
        margin-left: 150px;
    }

    .user-icon {
        margin-left: 25px;
        margin-right: 150px;
    }
}

@media (min-width: 801px) and (max-width: 1024px) {

    .menu-icon,
    .text-access {
        display: none;
    }

    .yolo-icon {
        margin-left: 50px;
    }

    .user-icon {
        margin-left: 25px;
        margin-right: 50px;
    }

    .selected-nav-route,
    .nav-route {
        color: white;
        font-size: 12px;
    }

    .selected-nav-route {
        color: #0bff93;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {

    .menu-icon,
    .text-access {
        display: none;
    }

    .yolo-icon {
        margin-left: 50px;
    }

    .user-icon {
        margin-left: 25px;
        margin-right: 50px;
    }

    .selected-nav-route,
    .nav-route {
        color: white;
        font-size: 15px;
    }

    .selected-nav-route {
        color: #0bff93;
    }
}