.yolo-bar ::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.yolo-bar ::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

.yolo-bar ::-webkit-scrollbar-thumb {
    background-color: #0B65FF;
    border-radius: 10px;
}

.yolo-bar ::-webkit-scrollbar-thumb:hover {
    background-color: #0845c8;
}

.yolo-chat-icon {
    width: 60px;
}

.yolo-chat-icon:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.yolo-bot {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0b65ff;
    color: white;
}

.yolo-bot button {
    width: 40px;
    height: auto;
    margin: 0px;
    margin-right: 15px;
    padding: 0px;
}

.chat-title {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 5px 10px;
}

.chat-title p {
    font-size: 13px;
    font-weight: 100;
    padding: 0px;
    margin: 0px;
    font-family: 'AmsiPro Bold';
    font-weight: 400;
    line-height: 22px;
}

.custom-component{
    width: 80%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #F2F2F2;
}

.custom-component a {
    width: 80%;
    color: #0B65FF;
    height: auto;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    border: #0B65FF solid 2.5px;
    border-radius: 12px;
    padding: 10px 15px;
    background-color: white;
    margin: 5px 0px;
    display: flex;
    align-items: center;
}

.custom-component img {
    height: 25px;
    width: 25px;
    margin-left: 5px;
}