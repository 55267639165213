@font-face {
    font-family: 'AmsiPro Ultra';
    src: url('../../assets/fonts/AmsiPro-Ultra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmsiPro Bold';
    src: url('../../assets/fonts/AmsiPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.header-container {
    width: 100%;
    height: 90vh;
    margin-top: 75px;
    background-image: linear-gradient(45deg, #FFFFFF 0%, #FFFFFF 60%, #0BFF93 200%);
}

.squares {
    width: 100%;
    height: 100%;
    background-image:
        linear-gradient(90deg, rgba(97, 97, 97, 0.1) 2px, transparent 2px),
        linear-gradient(0deg, rgba(97, 97, 97, 0.1) 4px, transparent 4px);
    background-size: 400px 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-halves {
    margin: 0px;
    padding: 0px;
    width: 35%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.halve-text {
    width: 28%;
}

.header-title {
    margin: 20px 0px;
    padding: 0px;
    text-align: start;
    font-size: 60px;
    font-family: 'AmsiPro Bold', sans-serif;
}

.header-title span {
    margin: 0px;
    margin-left: 10px;
    padding: 0px;
    color: #0b65ff;
}

.header-smallerText {
    font-size: 50px;
}

.header-subTitle {
    margin: 20px 0px;
    padding: 0px;
    font-size: 25px;
    font-family: 'AmsiPro', sans-serif;
}

.header-button {
    margin: 20px 0px;
    width: auto;
    height: auto;
    padding: 8px 30px 12px 30px;
    font-size: 25px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: #222635;
    color: white;
    font-family: 'AmsiPro Bold', sans-serif;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.header-button:hover {
    transform: scale(1.1);
    background-color: #222635;
}

.button-text-color {
    padding: 0px;
    margin: 5px 5px 5px 8px;
    color: #0bff93;
}

.svg {
    padding: 0px;
    margin: 8px 0px 0px 8px;
    width: 50px;
    height: 50px;
}

.header-images {
    margin: 0px;
    width: 100%;
}

@media (max-width: 600px) {
    .squares {
        background-image:
            linear-gradient(90deg, rgba(97, 97, 97, 0.1) 2px, transparent 2px),
            linear-gradient(0deg, rgba(97, 97, 97, 0.1) 4px, transparent 4px);
        background-size: 200px 200px;
        flex-direction: column;
    }
    
    .header-container {
        flex-direction: column;
        margin-top: 5vh;
    }

    .halve-text {
        margin-bottom: 30px;
    }

    .header-halves {
        width: 80%;
        height: 40%;
    }

    .header-title {
        margin: 8px 0px;
        font-size: 35px;
    }

    .header-smallerText {
        font-size: 25px;
    }

    .header-subTitle {
        margin: 8px 0px;
        font-size: 15px;
    }

    .header-button {
        margin: 8px 0px;
        padding: 5px 15px 5px 15px;
        font-size: 15px;
        border-radius: 8px;
    }

    .svg {
        padding: 0px;
        margin: 5px 0px 0px 4px;
        width: 25px;
        height: 25px;
    }
    
    .button-text-color {
        margin-left: 5px;
    }

}

@media (min-width: 600px) and (max-width: 800px) {
}

@media (min-width: 801px) and (max-width: 1270px ) {
   
    .squares {
        background-image:
            linear-gradient(90deg, rgba(97, 97, 97, 0.1) 2px, transparent 2px),
            linear-gradient(0deg, rgba(97, 97, 97, 0.1) 4px, transparent 4px);
        background-size: 250px 250px;
    }
    
    .header-title {
        margin: 8px 0px;
        font-size: 33px;
    }

    .header-smallerText {
        font-size: 28px;
    }

    .header-subTitle {
        margin: 8px 0px;
        font-size: 15px;
    }

    .header-button {
        margin: 8px 0px;
        padding: 5px 15px 5px 15px;
        font-size: 14px;
        border-radius: 8px;
    }

    .svg {
        padding: 0px;
        margin: 5px 0px 0px 4px;
        width: 25px;
        height: 25px;
    }
    
    .button-text-color {
        margin-left: 5px;
    }

}

@media (min-width: 1271px) and (max-width: 1700px) {
   
    .header-title {
        margin: 12px 0px;
        font-size: 40px;
    }

    .header-smallerText {
        font-size: 35px;
    }

    .header-subTitle {
        margin: 12px 0px;
        font-size: 18px;
    }

    .header-button {
        margin: 12px 0px;
        padding: 5px 15px 5px 15px;
        font-size: 18px;
        border-radius: 8px;
    }

    .svg {
        padding: 0px;
        margin: 5px 0px 0px 4px;
        width: 25px;
        height: 25px;
    }
    
    .button-text-color {
        margin-left: 5px;
    }

}