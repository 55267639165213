
.background-functoning {
    background: #0B65FF;
    flex-direction: column;
}

.title-functioning {
    font-family: 'AmsiPro Bold', sans-serif;
    color: white;
    text-align: center;
}

.container-functioning {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10vw;
    margin-left: 20vw;
}

.number-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
}

.text-box {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
}

.number-functioning {
    color: #0BFF93;
    font-size: 64px;
    font-family: 'AmsiPro Ultra', sans-serif;

}

.text-functioning {
    color: white;
    font-family: 'AmsiPro', sans-serif;
}

@media (max-width: 600px) {
    .title-functioning {
        font-size: 24px;
        width: 90vw;
        margin-top: 5vh;
    }

    .text-functioning {
        font-size: 16px;
    }

    .container-functioning {
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 5vh;
        justify-content: center;
        display: flex;
    }


    .number-box {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        justify-content: center;
        display: flex;
    }

    .text-box {
        margin-bottom: 10px;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        display: flex;
        margin-top: 25px;
    }

    .number-functioning {
        font-size: 48px;
    }

    .text-functioning {
        font-size: 12px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media (min-width: 600px) {
    .container-functioning {
        margin-top: 10vh;
    }

    .title-functioning {
        font-size: 40px;
        margin-top: 20vh;
    }

    .text-functioning {
        font-size: 22px;
    }

    .text-box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .text-functioning {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        margin-top: 10px;
    }
}
