.size {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

nav {
  background: #0B65FF;
  position: fixed;
  top: 0;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  z-index: 2;
}

.static-icons {
  position: fixed;
  right: 2%;
  bottom: 40%;
  width: auto;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  z-index: 2;
}

.facebook-blue-icon,
.instagram-blue-icon,
.linkedin-blue-icon,
.whatsapp-blue-icon,
.twitter-blue-icon {
  height: 50px;
  width: 50px;
  margin-top: 10px;
  transition: transform 0.5s ease, margin-top 0.6s ease, margin-bottom 0.6s ease;
}

.facebook-blue-icon:hover,
.instagram-blue-icon:hover,
.linkedin-blue-icon:hover,
.whatsapp-blue-icon:hover,
.twitter-blue-icon:hover {
  margin-bottom:20px;
  margin-top: 20px;
}

.facebook-blue-icon:hover{
  margin-bottom: 20px;
}
.twitter-blue-icon:hover{
  margin-top: 20px;
}

.instagram-blue-icon:hover{
  margin-bottom: 20px;
  margin-top: 40px;
}

.linkedin-blue-icon:hover{
  margin-bottom: 40px;
  margin-top: 20px;
}

.facebook-blue-icon:hover,
.instagram-blue-icon:hover,
.whatsapp-blue-icon:hover {
  transform: scale(1.1) translateY(-10px);
}

.linkedin-blue-icon:hover,
.twitter-blue-icon:hover {
  transform: scale(1.1) translateY(10px)
}

.background-header,
.background-benefits,
.background-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.featured-shops {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
}

@media (min-width: 600px) {
  .footer-container {
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .footer-text {
    margin-left: 15vw;
  }

  .background-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .background-benefits,
  .background-guide,
  .background-functoning {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .background-about-us,
  .faq-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .background-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 130vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

}

@media (max-width: 600px) {

  .background-functoning,
  .background-data,
  .background-about-us {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .background-benefits{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .faq-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .background-guide{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .background-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  .facebook-blue-icon,
  .instagram-blue-icon,
  .linkedin-blue-icon,
  .whatsapp-blue-icon,
  .twitter-blue-icon {
    display: none;
  }

  .footer-text {
    margin-left: 5vw;
  }

  .static-icons {
    display: none;
  }
}