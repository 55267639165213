.featured-shops {
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
    text-align: center;
    background: #F2F2F2;
    flex-direction: column;
}

.title-featured-shop {
    font-family: 'AmsiPro Bold', sans-serif;
    margin-bottom: 8vh;
    color: #222536;
}

.yolo-images {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 40px;
}

.yolo-images article {
    width: auto;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yolo-images img {
    width: 200px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1101px) {

    .title-featured-shop {
        font-size: 64px;
    }

    .yolo-image {
        margin-right: 50px;
        margin-left: 50px;
    }

    .yolo-image {
        height: auto;
        width: 200px;
    }

    .yolo-image-megatone {
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
        width: 200px;
        height: 50px;
    }

    .yolo-image-nuevas {
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
        width: 200px;
        height: 60px;
    }

}

@media (min-width: 600px) and (max-width: 1100px) {

    .title-featured-shop {
        font-size: 64px;
    }

    .yolo-image {
        margin-right: 0px;
        margin-left: 0px;
    }

    .yolo-images {
        margin-right: 10px;
        margin-left: 10px;
    }

    .yolo-image {
        height: auto;
        width: 150px;
    }

    .yolo-image-megatone {
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
        width: 150px;
        height: 40px;
    }

    .yolo-image-nuevas {
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
        width: 150px;
        height: 50px;
    }
}

@media (max-width: 600px) {

    .title-featured-shop {
        font-size: 30px;
    }

    .yolo-image {
        margin-right: 0px;
        margin-left: 0px;
    }

    .yolo-images {
        margin-right: 10px;
        margin-left: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .yolo-image {
        height: auto;
        margin-right: 5vw;
        margin-left: 5vw;
        width: 125px;
    }

    .yolo-image-megatone {
        height: auto;
        margin-right: 5vw;
        margin-left: 5vw;
        width: 125px;
        height: 35px;
    }

    .yolo-image-nuevas {
        height: auto;
        margin-right: 5vw;
        margin-left: 5vw;
        width: 125px;
        height: 40px;
    }
}