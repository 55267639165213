.background-benefits {
    background: #222635;
    flex-direction: column;
}

.title-benefits {
    font-family: 'AmsiPro Bold', sans-serif;
    color: white;
    text-align: center;
    margin-top: 0px;
    margin: 0px 20px;
}

.yolo-green-benefits {
    margin-left: 20px;
}

.container-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.rectangle-benefit {
    background-image: linear-gradient(0deg, transparent 0%, #FFFFFF 700%);
    border-radius: 15px;
    padding: 20px;
    width: 300px;
    text-align: center;
    color: white;
    font-family: 'AmsiPro', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #0BFF93;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 25px;
}

.icon-benefit {
    margin-bottom: 15px;
}

.rectangle-benefit p {
    margin: 0;
    font-size: 16px;
    font-family: 'AmsiPro Bold', sans-serif;
    color: white;
    line-height: 20px;
    margin-bottom: 0;
}

@media (min-width: 1701px) {
    .title-benefits {
        font-size: 40px;
    }

    .rectangle-benefit {
        width: 300px;
    }

    .container-benefits {
        width: 950px;
        padding-left: 300px;
        padding-right: 300px;
    }

}

@media (min-width: 600px) and (max-width: 1700px) {
    .title-benefits {
        font-size: 40px;
    }

    .container-benefits {
        width: 950px;
    }


}

@media (max-width: 600px) {
    .title-benefits {
        font-size: 24px;
        margin-top: 50px;
    }

    .rectangle-benefit {
        width: 80vw;
    }

    .container-benefits {
        width: 100%;
        margin-bottom: 50px;
        margin-top: 10vh;
    }

    .icon-benefit {
        width: 50px;
    }
    
    .yolo-green-benefits {
        margin-left: 20px;
        margin-top: 20px;
    }

}